// 头部搜索导航栏
<template>
  <div class="sld_home_top_search container">
    <div class="ld sld_home_top_search_left">
      <router-link tag="a" class="sld_logo_wrap flex_row_start_center" :to="`/index`">
        <!-- <img :src="configInfo.main_site_logo" :onerror='defaultImg' alt /> -->
		<img :src="configInfo.main_site_logo" alt="">
      </router-link>
    </div>
	<div class="catalog_con">
		<div>
			<div class="catalog" @click="switchCatalog">
				<img src="../assets/catalog.png" alt="">
				<span>{{L['分类']}}</span>
			</div>
		</div>
		<!-- 分类 -->
		<div v-show="catalogShow==true" @click="switchCatalog" class="bg"></div>
		<div v-show="catalogShow==true" class="catalog_list">
			<div class="catalog catalog-pos" v-show="catalogShow==true" @click="switchCatalog">
				<img src="../assets/close.png" alt="">
				{{L['Close']}}
			</div>
			<div class="catalog_left">
				<!-- on -->
				<div 
				 :class="[selectTabIndex == firstIndex?'on':'','catalog_item']"
				 @mouseenter="selectTab(firstIndex, item.categoryId)" v-for="(item,firstIndex) in cateData.data" :key="firstIndex">
					{{item.categoryName}}
					<img src="../assets/icon-right.png" alt="">
				</div>
			</div>
			<div class="catalog_right">
				<div class="tit" v-if="cateData.data[selectTabIndex]">
					{{cateData.data[selectTabIndex].categoryName }}
				</div>
				<div class="catalog_3">
					<div class="catalog_3_item" @click.stop="toGoodsList(item)" v-for="(item,index) in  cateCacheChild" :key="index">
						<img :src="item.categoryImage" alt="">
						<p>{{item.categoryName}}</p>
					</div>
					
				</div>
				<div class="tit2">
					{{L['特惠']}}!!! 
					<img src="../assets/icon-right.png" alt="">
				</div>
				<div class="goods_recommend_list">
					<div class="goods_recommend_item"  @click="goGoodsDetail(item.defaultProductId)" v-for="(item,index) in recommendList" :key="index">
						<img :src="item.goodsImage" alt="">
						<div class="goods_recommend_box">
							<div class="goods_recommend_name">
								{{item.goodsName}}
							</div>
							<div class="goods_recommend_price">
								<span class="price">{{$formatMoney(item.goodsPrice)}} {{ L['₸'] }}</span>
								<span class="reduce" v-show="item.marketPrice>item.goodsPrice">-{{ Math.floor((item.marketPrice - item.goodsPrice)/item.marketPrice*100)}}%</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
	</div>
	<!-- 搜索 -->
    <div class="sld_seach_wrap">
		<!-- 背景 -->
		<!-- v-show="searchLabelShow" -->
	  <div v-show="bgShow" @click="searchClose" class="bg"></div>
      <div class="sld_seach_box ld">
        <!-- <div class="form" @submit.prevent>
          <input v-model="keyword" type="text" class="text" autocomplete="off" :placeholder="L['请输入关键词']"
            @keyup.enter="search" ref="searchInput" @focus="inputFocus" @input="inputChange" @blur="inputBlur" />
          <input type="submit" :value="L['搜索']" class="button" @click="search" />
        </div> -->
		<div class="search_con">
			<div :class="[searchCategoryId != 0?'on':'','search_label']" @click="searchShow">
				<!-- {{searchCategoryId}} -->
				<span >{{L[searchLabel]}}</span>  
				<i></i>
				<!-- <img src="../assets/down3.png" alt=""> -->
			</div>
			<div class="search_inp">
				<input v-model="keyword" @click="clickInp" type="text" :placeholder="L['在dzon找你想买的任何东西']">
			</div>
			<!-- 搜索历史下拉框 -->
			<div v-show="historyPanelShow" class="history_panel" >
				<div class="history_con">
					<div class="tit">
						<span class="txt">{{L['History']}}</span>
						<span class="clear" @click="historyClear">{{L['Clear all']}}</span>
					</div>
					<div class="history_list">
						<div class="history_item" @click="historySearch(item)" v-for="(item,index) in historyList" :key="index">
							<i class="time"></i>
							<span>{{item.keyword}}</span>
							<i class="close" @click.stop="historyDel(index)"></i>
						</div>
					</div>
					<div class="history_recommend_con">
						<div class="history_recommend_tit">
							{{L['Trending Now']}}
						</div>
						<div class="history_recommend_list">
							<div class="history_recommend_item"  @click="goGoodsDetail(item.defaultProductId)" v-for="(item,index) in recommendList" :key="index">
								<img :src="item.goodsImage" alt="">
								<p class="price" >
									<span>  
										<span class="money_number" >{{$formatMoney(item.goodsPrice)}} {{ L['₸'] }} </span>
									</span>
									<i>{{$formatMoney(item.marketPrice)}} {{ L['₸'] }} </i>
								</p>
								<p class="title" >
									<a href="javascript:void(0)" title="" >
										{{item.goodsName}}
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 标签框 -->
			<div v-show="searchLabelShow" class="search_label_panel">
				<!-- 搜索标签 -->
				<div  class="search_label_con">
					<!-- 全部分类默认为0,但是传给接口需要传空 -->
					<div class="all" @click="clickLabel({categoryId:0})">{{ L['All'] }}</div>
					<div class="search_label_list">
						<div class="search_label_item" @click="clickLabel(item)" v-for="(item,firstIndex) in cateData.data" :key="firstIndex">
							{{item.categoryName}}
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="search_btn" @click="search">
			<img src="../assets/search2.png" alt="">
		</div>
      </div>
	  
      <div class="search_association" id="searchA" v-show="SAList.length && SAShow">
        <div class="s_a_item" v-for="(item, index) in SAList" @mousedown="quickSearch(item.wordsContent, 'associ')"
          :key="index">
          <!-- 因为click事件与blur事件冲突，blur事件优先，于是换成mousedown -->
          <div>{{ item.wordsContent }}</div>
          <div>{{ item.searchGoodsNum }}{{ L['个商品'] }}</div>
        </div>
      </div>
    </div>
	
	<div class="profile_menu">
		<div  @click="openLoingModal('user')">
			<img src="../assets/Me.png" alt="">
			<p>
				<!-- {{ memberInfo  }} -->
				<!-- {{  Object.keys(memberInfo).length }} -->
				<span v-show="Object.keys(memberInfo).length == 0"> 
					{{L['登录']}}
				</span>
				<span v-show="memberInfo">
					{{
						memberInfo.memberNickName ||
						memberInfo.memberName
					}}
				</span>
				
			</p>
		</div>
		<div @click="openLoingModal('order')">
			<img src="../assets/ShippingBox.png" alt="">
			<p>{{L['订单']}}</p>
		</div>
		<div @click="openLoingModal('cart')">
			 <!-- <router-link target="_blank" :to="`/cart/index`"> -->
				<img src="../assets/Cart.png" alt="">
				<p>{{L['购物车']}}</p>
				<i>{{
                cartData.data && cartData.data.availableCartNum ?
                cartData.data.availableCartNum : 0
              }}</i>
			<!-- </router-link> -->
		</div>
	</div>
	
	<!-- v-if="searchBarFixed" -->
    <div v-show="false" class="container_header">
      <div class="container_header_box flex_row_start_center">
        <div class="sld_cart_wrap fixed_sld_cart_wrap">
          <router-link tag="a" class="sld_logo_wrap flex_row_start_center" :to="`/index`">
            <img :src="configInfo.main_site_logo" :onerror='defaultImg' alt />
          </router-link>
        </div>
        <div class="sld_seach_wrap" style="margin-right: 90px">
          <div class="sld_seach_box ld">
            <div class="form">
              <input v-model="keyword" type="text" class="text" autocomplete="off" style="color:rgb(153,153,153);"
                :placeholder="L['请输入关键词']" ref="searchInput" @focus="inputFocus" />
              <input type=" submit" :value="L['搜索']" class="button" @click="search" />
            </div>
          </div>
        </div>
        <div class="sld_cart_wrap">
          <dl class>
            <dt class="ld cart_icon_text_wrap" style="border-bottom: 1px solid rgb(239, 239, 239);">
              <span class="iconfont icongouwuche"></span>
              <router-link target="_blank" :to="`/cart/index`">{{ L['我的购物车'] }}</router-link>
              <!-- 没有商品显示0 -->
              <div class="cart_goods_num">{{
                cartData.data && cartData.data.availableCartNum ?
                cartData.data.availableCartNum : 0
              }}</div>
            </dt>
            <dd class="cart_more_view">
              <cart-model></cart-model>
            </dd>
          </dl>
        </div>
      </div>

    </div>
	
    <div v-show="false" class="sld_cart_wrap">
      <dl class>
        <router-link target="_blank" :to="`/cart/index`">
          <dt class="ld cart_icon_text_wrap" style="border-bottom: 1px solid rgb(239, 239, 239);">
            <span class="iconfont icongouwuche"></span>
            <span>{{ L['我的购物车'] }}</span>
            <!-- 没有商品显示0 -->
            <div class="cart_goods_num">{{
              cartData.data && cartData.data.availableCartNum ?
              cartData.data.availableCartNum : 0
            }}
            </div>
          </dt>
        </router-link>
        <dd class="cart_more_view">
          <cart-model></cart-model>
        </dd>
      </dl>
    </div>
	<SldLoginModal :visibleFlag="loginModalVisibleFlag" @closeLoingModal="closeLoingModal" />
			
  </div>
</template>

<script>
import { reactive, getCurrentInstance, ref, watch, watchEffect, onMounted} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import CartModel from '@/components/CartModel';
import SldLoginModal from '@/components/loginModal'
export default {
  name: "SldHomeTopSearch",
  components: {
    CartModel,
	SldLoginModal
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute()
    const L = proxy.$getCurLanguage();
    const store = useStore();
	const memberInfo = store.state.memberInfo;
    const cartData = reactive({ data: {} }); //获取vux的store中的购物车数据
    const logoUrl = "";
    const configInfo = ref(store.state.configInfo)
    const defaultImg = ref('this.src="' + require('../assets/common_top_logo.png') + '"')
    const keyword = ref(route.query.keyword);
    const searchUrl = require("../assets/header/magnify.png");
    const cartUrl = require("../assets/header/cart.png");
    const picture = require("../assets/picture.png");
    const goods_name = "";
    const searchBarFixed = ref(false);
    const hotList = reactive({ data: [] }); //热门搜索词列表
    const tmpHotList = ref([])
    const delGoodsCartIds = ref(''); //删除商品的id
    const SAList = ref([])
    const SAShow = ref(false)
	const catalogShow = ref(false)
    const loginModalVisibleFlag = ref(false) //登录弹框是否显示，默认不显示
    onMounted(() => {
      cartData.data = store.state.cartListData;
      getInitData();
	  getTopCategory();
	  getRecommendList();
	  // 为了维持进入详情页搜索框状态
	  let searchLabelTemp = getQueryParamValue('searchLabel')
	  let categoryIdTemp = getQueryParamValue('categoryId')
	  searchLabel.value = searchLabelTemp?searchLabelTemp:'All'
	  searchCategoryId.value = categoryIdTemp?categoryIdTemp:''
	  // 历史记录
	  if(sessionStorage.getItem('history')){
		let temp = JSON.parse(sessionStorage.getItem('history'));
		historyList.splice(0, historyList.length, ...temp.slice());
	  }
    })
	// 获取地址栏参数
	const getQueryParamValue = (paramName) => {
	  const queryString = window.location.search.substring(1); // 去掉问号
	  const paramsArray = queryString.split('&');

	  for (const param of paramsArray) {
		const [key, value] = param.split('=');
		if (key === paramName) {
		  return decodeURIComponent(value.replace(/\+/g, ' '));
		}
	  }

	  return ''; // 如果参数不存在，返回 null 或其他默认值
	}
	
    const getInitData = () => {
      proxy
        .$get("v3/system/front/setting/getSettings?names=hot_search_words")
        .then(res => {
          if (res.state == 200) {
            let tmp_data = res.data[0] ? res.data[0].split(",").filter(i => i != '') : [];
            hotList.data = tmp_data;
            tmpHotList.value = tmp_data
            hotList.data = hotList.data.map(key => {
              if (key.length > 10) {
                // return key.substring(0, 10) + '...'
				return key
              } else {
                return key
              }
            })
          }
        });
    };
    watch(() => store.state.configInfo, () => {
      if (!configInfo.value.main_site_logo) {
        configInfo.value = store.state.configInfo;
      }
    })

    const cart_num = "";
    watchEffect(() => {
      if (route.query.keyword) {
        keyword.value = route.query.keyword;
      } else {
        keyword.value = "";
      }
      cartData.data = store.state.cartListData;
    });

	var historyList = reactive([])
    //搜索事件
    const search = () => {
      // proxy.$refs.searchInput.style.color = 'rgb(153,153,153)'
      if (keyword.value) {
		let params = {
			 keyword: keyword.value,
			 categoryId:searchCategoryId.value,
			 searchLabel:searchLabel.value
		}
		// 最多显示三个
		if(historyList && historyList.length == 3){
			historyList.shift();
		}
		historyList.push(params)
		sessionStorage.setItem('history',JSON.stringify(historyList))
		historyPanelShow.value = false
		bgShow.value = false
        router.push({
          path: `/goods/list`,
          query: params
        });
		
      }
    };
	
	const historyDel = (index) =>{
		historyList.splice(index, 1);
		sessionStorage.setItem('history',JSON.stringify(historyList))
	}
	const historyClear = ()=>{
		historyList.length = 0;
		sessionStorage.setItem('history',[])
	}
	
	const historySearch = (item) =>{
		keyword.value = item.keyword
		// searchCategoryId.value = item.categoryId
		search()
	}

    const searchAssociation = (input) => {
      proxy.$get('v3/goods/front/goods/searchWords/list', {
        keyWord: input
      }).then(res => {
        if (res.state == 200) {
          SAList.value = res.data
        }
      })
    }

    const inputChange = (e) => {
      let input = e.target.value.trim()
      SAShow.value = true

      if (input) {
        searchAssociation(input)
      } else {
        SAList.value = []
      }
    }
    //热门搜索事件
    const quickSearch = (val, type) => {
      if (type == "quick") {
        router.push({ path: `/goods/list`, query: { keyword: tmpHotList.value[val] } });
      } else {
        if (val == route.query.keyword) {
          keyword.value = val
        } else {
          router.push({ path: `/goods/list`, query: { keyword: val } });

        }
      }
    };

    const inputFocus = () => {
      proxy.$refs.searchInput.style.color = '#333'
      SAShow.value = true
      if (keyword.value && SAList.value.length == 0) {
        searchAssociation(keyword.value)
      }
    }

    const inputBlur = () => {
      SAShow.value = false
    }

    //滚动事件
    const handleScroll = () => {
      var height = 600;
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > height) {
        searchBarFixed.value = true;
      } else {
        searchBarFixed.value = false;
      }
    };
    window.addEventListener("scroll", handleScroll);

    //删除商品 curCartItem: 当前商品信息
    const delCartGoods = (curCartItem) => {
      if (store.state.loginFlag) {
        //已登录
        delGoodsCartIds.value = curCartItem.cartId;
      } else {
        //未登录
        delGoodsCartIds.value = curCartItem.goodsId;
      }
      confirmDelGoodsModel();
    };
    //确定删除商品
    const confirmDelGoodsModel = () => {
      if (store.state.loginFlag) {
        // 已登录
        let params = {
          cartIds: delGoodsCartIds.value,
        };
        proxy
          .$post("v3/business/front/cart/deleteCarts", params)
          .then((res) => {
            if (res.state == 200) {
              cartData.data = res.data;
              ElMessage.success(L["删除成功！"]);
            } else {
              ElMessage.error(res.msg);
            }
          });
      } else {
        // 未登录
        //单个商品删除
        cartData.data.storeCartGroupList.map((storeItem, storeIndex) => {
          storeItem.promotionCartGroupList.map((promotItem) => {
            promotItem.cartList.map((cartItem, cartIndex) => {
              if (cartItem.goodsId == delGoodsCartIds.value) {
                promotItem.cartList.splice(cartIndex, 1);
              }
            });
            if (promotItem.cartList.length == 0) {
              storeItem.promotionCartGroupList = [];
            }
            if (storeItem.promotionCartGroupList.length == 0) {
              cartData.data.storeCartGroupList.splice(storeIndex, 1);
            }
          });
        });
        ElMessage.success(L["删除成功！"]);
      }
      store.commit("updateCartListData", cartData.data);
    };
	
	//前往具体的页面，type为具体的页面地址，param为参数对象
	const goToByPush = (type, param = {}) => {
	  if (type == "/service") {
	    let chatInfo = {
	      storeId: 0,
	      vendorAvatar: configInfo.platform_customer_service_logo,
	      storeName: configInfo.platform_customer_service_name,
	      showData: {},
	      source: "pc商城"
	    };
	    store.commit("saveChatBaseInfo", chatInfo);
	
	    let newWin = router.resolve({
	      path: type,
	      query: param
	    });
	
	    window.open(newWin.href, "_blank");
	    return;
	  };
	  
	  router.push({
	    path: type,
	    query: param
	  });
	};
	// 切换分类
	const switchCatalog = () => {
	  catalogShow.value = !catalogShow.value
	};
	
	const cateData = reactive({ data: [] })
	// 获取一级分类
	const getTopCategory = () => {
	    proxy.$get('v3/goods/front/goods/category/topCategory').then(res => {
	        if (res.state == 200) {
	            cateData.data = res.data
				let params = {
				    categoryId1: res.data[0].categoryId
				}
				getSortChild(0,params)
	        }
	    })
	}
	const selectTabIndex = ref(0)
	var timeout = null
	// 点击一级分类
	const selectTab = (index, categoryId) => {
		selectTabIndex.value = index
	    let params = {
	        categoryId1: categoryId
	    }
	    getSortChild(index, params)
		// 防抖
		// if(timeout){
		// 	clearTimeout(timeout)
		// }
		// timeout = setTimeout(() => {
		// 	getSortChild(index, params)
		// }, 500);

	}
	const cateCacheChild = ref([])
	// 获取第二第三级分类
	const getSortChild = (index, params) => {
			 proxy.$get('v3/goods/front/goods/category/bottomCategory', params).then(res => {
				if (res.state == 200) {
					let data = res.data;
					// 数组中的children为三级分类,把他们组合成一个数组显示到页面上 flat() 是将多维数组（嵌套数组）转换为一维数组
					let combinedChildren = data.map(item => item.children).filter(children => children !== null).flat();
					cateCacheChild.value = combinedChildren
				}
			})
	}
	
	const toGoodsList = (item) => {
	    let newWin = router.resolve({
	        path: '/goods/list',
	        query: {
	            categoryId: item.categoryId,
	            g: item.grade,
	            pid: item.pid
	        }
	    })
	    window.open(newWin.href, '_blank');
	}
	const recommendList = ref([])
	// 精品推荐
	const getRecommendList = (index, params) => {
	    proxy.$get('v3/goods/front/goods/recommendList',{queryType:"cart"}).then(res => {
	        if (res.state == 200) {
				recommendList.value = res.data.list
	        }
	    })
	}
	//去商品详情页
	const goGoodsDetail = (productId) => {
	    proxy.$goGoodsDetail(productId);
	};
	const searchLabelShow = ref(false)
	const historyPanelShow = ref(false)
	const bgShow = ref(false)
	const searchClose = () =>{
		searchLabelShow.value = false
		historyPanelShow.value = false
		bgShow.value = false
	}
	
	const searchShow = () => {
		historyPanelShow.value = false
		searchLabelShow.value = true
		bgShow.value = true
	}
	const searchLabel = ref('All')
	const searchCategoryId = ref('')
	const clickLabel = (item) =>{
		 searchCategoryId.value = item.categoryId
		 searchLabel.value = item.categoryName || 'All'
		 
		 searchLabelShow.value = false
		 historyPanelShow.value = false
		 bgShow.value = false
	}
	const clickInp = () =>{
		searchLabelShow.value = false
		historyPanelShow.value = true
		bgShow.value = true
	}

    //关闭登录弹框
    const closeLoingModal = () => {
        loginModalVisibleFlag.value = false
    }

	//打开登录弹框
    const openLoingModal = (type) => {
		// loginModalVisibleFlag.value = true
		//已登录
		if(store.state.loginFlag){
			// 去个人中心
			if(type == "user"){
				goToByPush('/member/index')
			}
			// 去订单
			if(type == "order"){
				goToByPush('/member/order/list', { 'orderState': 0 })
			}
			// 去购物车
			if(type == "cart"){
				goToByPush('/cart/index')
			}
			
		}else{
			loginModalVisibleFlag.value = true
		}
        
    }

	

    return {
      L,
	  memberInfo,
	  loginModalVisibleFlag,
	  openLoingModal,
	  closeLoingModal,
	  historyClear,
	  searchCategoryId,
	  historyDel,
	  historySearch,
	  historyList,
	  searchLabel,
	  clickInp,
	  clickLabel,
	  searchShow,
	  bgShow,
	  searchLabelShow,
	  historyPanelShow,
	  searchClose,
	  goGoodsDetail,
	  recommendList,
	  toGoodsList,
	  selectTabIndex,
	  cateData,
	  selectTab,
	  cateCacheChild,
      logoUrl,
      searchUrl,
      cartUrl,
      picture,
      goods_name,
      hotList,
      cart_num,
      searchBarFixed,
      keyword,
      search,
      quickSearch,
      delGoodsCartIds,
      delCartGoods,
      cartData,
      configInfo,
      defaultImg,
      inputFocus,
      tmpHotList,
      searchAssociation,
      SAList,
      inputChange,
      inputBlur,
      SAShow,
	  goToByPush,
	  switchCatalog,
	  catalogShow
    }
  }
};
</script>

<style lang="scss" scoped>
a:active,
a:visited {
  color: #333;
}

.sld_home_top_search {
  height: 80px;
  position: sticky;
  top: 0;
  z-index: 12;
  width: 1320px;
  margin: 0 auto;
  padding-left: 0px;
  padding-right: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  background: #fff;

  .container_header {
    width: 100%;
    position: fixed;
    background-color: #fff;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    border-bottom: 2px solid var(--color_main);

    .sld_seach_wrap,
    .sld_cart_wrap {
      margin: 5px;
      margin-right: 40px;
      position: relative;
    }

    .container_header_box {
      width: 1200px;
      margin: 0 auto;

      .fixed_sld_cart_wrap {
        margin-right: 170px;

        a {
          width: 100%;
          height: 100%;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }
  }

  .container_header {
    -webkit-animation: searchTop 0.5s ease-in-out;
    animation: searchTop 0.5s ease-in-out;
  }

  @-webkit-keyframes searchTop {
    0% {
      top: -50px;
    }

    to {
      top: 0;
    }
  }

  @keyframes searchTop {
    0% {
      top: -50px;
    }

    to {
      top: 0;
    }
  }

//   &:before {
//     display: table;
//     content: " ";
//   }

  .sld_home_top_search_left {
    position: relative;
    // float: left;
    // width: 331px;
    // margin-top: 24px;

    .sld_logo_wrap {
      display: block;
      width: 144px;
    //   height: 60px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .catalog_con{
	  
	  .bg{
		 background: rgba(0, 0, 0, 0.70);
		  height: 100vh;
		  left: 0;
		  position: fixed;
		  top: 0;
		  width: 100vw;
		  z-index: 12;
	  }
	  .catalog{
		  position: relative;
		  z-index: 11;
	  	  // float: left;
	  	  font-size: 14px;
	  	  display: inline-flex;
	  	  justify-content: center;
	  	  align-items: center;
	  	//   width:100px;
		  padding-left: 16px;
		  padding-right: 24px;
	  	  height: 40px;
	  	  border-radius: 24px;
	  	  color: #FFF;
	  	  background: #005CF6;
	  	  gap: 8px;
	  	//   margin-left: 24px;
		  cursor: pointer;
	  }
	  .catalog_list{
		  width: 1320px;
		  height: 770px;
		  background: #fff;
		  border-radius: 24px;
		  position: fixed;
		  left: 50%;
		  top: 112px;
		  transform: translateX(-50%);
		  z-index: 13;
		  padding:32px 16px 16px 40px;
		  .catalog-pos{
			  position: absolute;
			  left: 165px;
			  top: -61px;
		  }
		  .catalog_left{
			  overflow-y: auto;
			  width: 240px;
			  height: 710px;
			  float: left;
			  .catalog_item{
				  display: flex;
				  align-items: center;
				  justify-content: space-between;
				  cursor: pointer;
				  font-size: 16px;
				  font-style: normal;
				  font-weight: 500;
				  line-height: 24px; 
				  letter-spacing: 0.15px;
				  color: #1B1B1B;
				  padding: 8px;
				  margin-bottom: 8px;
			  }
			  .on{
				  color: #005CF6;
				  background: #EFF0FF;
				  border-radius: 12px;
			  }
		  }
		  .catalog_right{
			  float: right;
			  width: 992px;
			  height: 710px;
			  overflow-y: auto;
			  .tit{
				  color: #1B1B1B;
				  font-size: 32px;
				  font-style: normal;
				  line-height: 40px; 
			  }
			  .catalog_3{
				  display: flex;
				  flex-wrap: wrap;
				  margin-top: 32px;
				  .catalog_3_item{
					  width: 120px;
					  height: 160px;
					  padding: 8px;
					  text-align: center;
					  margin-right: 16px;
					  margin-bottom: 16px;
					  img{
						  width: 88px;
						  height: 88px;
						  border-radius: 48px;
					  }
					  p{
						  width: 104px;
						  text-overflow: -o-ellipsis-lastline;
						  overflow: hidden;
						  text-overflow: ellipsis;
						  display: -webkit-box;
						  -webkit-line-clamp: 2;
						  line-clamp: 2;
						  -webkit-box-orient: vertical;
					  }
				  }
			  }
			  .tit2{
				  height: 40px;
				  line-height: 40px;
				  display: flex;
				  align-items: center;
				  font-size: 14px;
				  line-height: 20px; 
				  letter-spacing: 0.1px;
				  color: #005CF6;
				  margin-bottom: 8px;
			  }
			  .goods_recommend_list{
				  display: flex;
				  flex-wrap: wrap;
				  .goods_recommend_item{
					  display: flex;
					  align-items: center;
					  width: 256px;
					  height: 112px;
					  padding: 8px;
					  margin-right: 16px;
					  margin-bottom: 8px;
					  cursor: pointer;
					  img{
						  width: 96px;
						  height: 96px;
						  border-radius: 8px;
						  margin-right: 8px;
					  }
					  .goods_recommend_box{
						  height: 96px;
						  .goods_recommend_name{
							  width: 136px;
							  height: 64px;
							  font-size: 12px;
							  line-height: 16px;
							  letter-spacing: 0.4px;
							  text-overflow: -o-ellipsis-lastline;
							  overflow: hidden;
							  text-overflow: ellipsis;
							  display: -webkit-box;
							  -webkit-line-clamp: 4;
							  line-clamp: 4;
							  -webkit-box-orient: vertical;
							  margin-bottom: 8px;
						  }
						  .goods_recommend_price{
							  display: flex;
							  justify-content: space-between;
							  align-items: center;
							  .price{
								  font-size: 16px;
								  line-height: 24px; 
								  letter-spacing: 0.5px;
								  color: #F84C0B;
							  }
							  .reduce{
								  border-radius: 4px;
								  color: #F84C0B;
								  border: 1px solid #F84C0B;
								  padding: 4px 8px;
							  }
						  }
					  }
				  }
			  }
		  }
	  }
  }
  
  .profile_menu{
	  display: flex;
	  justify-content: center;
	  align-items: center;
	//   margin-left: 47px;
	  >div{
		  width: 94px;
		  height: 64px;
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  flex-direction: column;
		  cursor: pointer;
		//   margin-left: 15px;
		  position: relative;
		  p{
			text-align: center;
		  }
		  img{
			  margin: 0 auto;
    		  display: block;
			  margin-bottom: 4px;
		  }
		  i{
			 background: #F84C0B; 
			 border-radius: 100px;
			 color: #fff;
			 padding: 0 6px;
			 font-style: normal;
			 position: absolute;
			 left: 55px;
			 top: 1px;
			 height: 16px;
			 line-height: 16px;
		  }
	  }
  }
  .sld_seach_wrap {
    // float: left;
    // width: 660px;
    // padding-top: 4px;
    // margin-top: 30px;
    position: relative;
	.bg{
	  background: rgba(0, 0, 0, 0.70);
	   height: 100vh;
	   left: 0;
	   position: fixed;
	   top: 0;
	   width: 100vw;
	   z-index: 11;
	}
    .sld_seach_box {
      width: 648px;
      z-index: 11;
      height: 40px;
	//   margin-left: 45px;
	  border-radius: 8px;
      border: 2px solid var(--color_main);
	  background: var(--color_main);
	  display: flex;
	  justify-content: flex-start;
	  align-items: center;
	  // overflow: hidden;
	  .search_con{
		  display: flex;
		  justify-content: flex-start;
		  align-items: center;
		  width: 576px;
		  background: #fff;
		  position: relative;
		  /* overflow: hidden; */
		  border-radius: 6px;
		  .history_panel{
			  
			  .history_con{
				  width: 648px;
				  height: 897px;
				  border-radius: 6px;
				  background: #fff;
				  position: absolute;
				  left: 0;
				  top: 48px;
				  padding-top: 16px;
				  .tit{
					  display: flex;
					  align-items: center;
					  justify-content: space-between;
					  height: 40px;
					  padding-left:16px;
					  padding-right: 24px;
					  .txt{
						  font-size: 24px;
						  line-height: 32px; 
					  }
					  .clear{
						  width:68px;
						  text-align: center;
						//   line-height:28px;
						  cursor: pointer;
						  font-size: 12px;
						  font-weight: 500;
						  color: #005CF6;
						  letter-spacing: 0.5px;
					  }
				  }
				  .history_list {
					  .history_item{
						  cursor: pointer;
						  margin: 0 8px;
						  width: 632px;
						  line-height: 44px;
						  border-radius: 8px;
						  display: flex;
						  align-items: center;
						  position: relative;
						  border-bottom: 1px solid #F1F1F1;
						  .time{
							 margin-left: 8px;
							 margin-right: 4px;
							 display: inline-block;
							 vertical-align: middle;
							 width: 20px;
							 height: 20px;
							 background: url('../assets/history1.png') center center no-repeat; 
							 background-size: 100% 100%;
						  }
						  span{
							  font-size: 14px;
							  letter-spacing: 0.25px;
						  }
						  .close{
							  cursor: pointer;
							  position: absolute;
							  right: 8px;
							  top:12px;
							  display: inline-block;
							  vertical-align: middle;
							  width: 20px;
							  height: 20px;
							  background: url('../assets/close3.png') center center no-repeat; 
							  background-size: 100% 100%;
						  }
						  
					  }
					  .history_item:last-child{
						  border-bottom: none;
					  }
					  .history_item:hover{
						  background: #EFF0FF;
						  span{
							  color:#005CF6;
						  }
						  .time{
							  background: url('../assets/history2.png') center center no-repeat;
							  background-size: 100% 100%;
						  }
						  .close{
							  display: inline-block;
							  vertical-align: middle;
							  width: 20px;
							  height: 20px;
							  background: url('../assets/close2.png') center center no-repeat; 
							  background-size: 100% 100%;
						  }
					  }
					 
				  }
				  .history_recommend_con{
					  .history_recommend_tit{
						  font-size: 24px;
						  line-height: 48px; /* 133.333% */
						  padding: 0 16px;
						  margin-top: 16px;
						  margin-bottom: 8px;
					  }
					  .history_recommend_list{
						  display: flex;
						  flex-wrap: wrap;
						  padding-left: 16px;
						  height: 630px;
						  overflow-x: auto;
						  .history_recommend_item{
							  width:184px;
							  margin-right: 24px;
							  margin-bottom: 24px;
							  img{
								  width:184px;
								  height: 184px;
								  border-radius: 8px;
								  display: block;
							  }
							  .price{
								   margin: 8px auto;
								   color: var(--color_price);
									.money_number {
									  font-size: 16px;
									  color: var(--color_price);
									  font-size: 16px;
									  line-height: 24px;
									  letter-spacing: 0.5px;
									  margin-right: 4px;
									}
									i{
										color: #919191;
										font-size: 12px;
										transform: scale(0.91,0.91) ;
										font-style: normal;

									}
							  }
							  .title{
								  a{
									  color: #1B1B1B;
									  line-height: 16px !important;
									  font-size: 12px;
									  overflow: hidden;
									  text-overflow: ellipsis;
									  display: -webkit-box;
									  -webkit-line-clamp: 2;
									  -webkit-box-orient: vertical;
									  word-break: break-word;
									  white-space: normal;
								  }
							  }
						  }
						  .history_recommend_item:nth-child(3n){
							  margin-right: 0;
						  }
					  }
				  }
			  }
			  
		  }
		  .search_label_panel{
			  .search_label_con{
				  padding: 36px 40px;
				  border-radius: 24px;
				  background: #fff;
				  width: 688px;
				  height: 560px;
				  // position: fixed;
				  // left: 50%;
				  // top: 50%;
				  // transform: translate(-50%,-50%);
				  
				  position: absolute;
				  left: -20px;
				  top: 48px;
				  
				  font-size: 14px;
				  line-height: 20px; 
				  letter-spacing: 0.25px;
				  .all{
					  line-height: 36px;
					  margin-bottom: 16px;
					  cursor: pointer;
				  }
				  .search_label_list{
					  display: flex;
					  flex-wrap: wrap;
					  .search_label_item{
						  width: 50%;
						  line-height: 36px;
						  cursor: pointer;
					  }
				  }
			  }
		  }
		 
		  .search_label{
			  // width: 61px;
			  padding-left: 16px;
			  padding-right: 8px;
			  height: 32px;
			  border-radius: 4px;
			  color: #474747;
			  display: flex;
			  align-items: center;
			  justify-content: center;
			  background: #F1F1F1;
			  margin-left: 2px;
			  cursor: pointer;
			  // max-width: 170px;
			  // overflow: hidden;
			  text-overflow: ellipsis;
			  white-space: nowrap;
			  i{
				  display: inline-block;
				  width: 16px;
				  height: 16px;
				  background: url('../assets/down3.png') center center no-repeat;
				  background-size: 100% 100%;
			  }
			  
		  }
		  .search_label.on{
			   background: #005CF6;
			   color: #fff;
			  i{
				width: 16px;
				height: 16px;
			  	background: url('../assets/down2.png') center center no-repeat;
				background-size: 100% 100%;
			  }
		  }
		  .search_inp{
			  margin-left: 8px;
			  width: 86%;
			  input{
				  width: 100%;
				  height: 36px;
				  color: #919191;
				  font-size: 12px;
				  border-radius: 8px;
			  }
		  }
	  }
	  .search_btn{
		  background: #005CF6;
		  height: 40px;
		  width: 72px;
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  border-radius: 0 6px 6px 0;
	  }
	  
      .form {
        background-color: var(--color_main);
        height: 34px;
        overflow: hidden;

        .text {
          height: 36px;
          background-color: #fff;
          width: 471px;
          -webkit-appearance: none;
          -webkit-border-radius: 0;
          height: 34px;
          color: rgb(153, 153, 153);
          padding: 5px 5px 5px 10px;
          background-position: 0 -360px;
          background-color: #fff;
          background-repeat: repeat-x;
          line-height: 20px;
          font-family: arial, "\5b8b\4f53";
          font-size: 12px;
          outline: none;
          border: none;
        }

        input {
          margin: 0;
          padding: 0;
          height: 34px;
          border: 0;
        }

        input::placeholder {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgb(153, 153, 153);
        }

        ::-webkit-input-placeholder {
          margin-left: 20px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgb(153, 153, 153);
        }

        /* 使用webkit内核的浏览器 */
        :-moz-placeholder {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgb(153, 153, 153);
        }

        /* Firefox版本19+ */
        :-ms-input-placeholder {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgb(153, 153, 153);
        }

        /* IE浏览器 */

        .button {
          width: 103px;
          background: var(--color_main_bg);
          font-size: 16px;
          font-weight: 600;
          color: #fff;
          float: right;
          cursor: pointer;
          text-align: center;
        }
      }

      .hot_search_wrap {
        height: 30px;
        line-height: 30px;
        color: #999;
        overflow: hidden;

        strong {
          float: left;
          font-weight: 400;
        }

        a {
          color: #666;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;

          &:link,
          &:visited {
            float: left;
            margin-right: 10px;
          }

          &:hover {
            color: var(--color_main);
          }
        }
      }
    }

    .search_association {
      background: #fff;
      position: absolute;
      top: 43px;
      overflow: hidden;
      position: absolute;
      left: 0;
      width: 580px;
      border: 1px solid #ccc;
      background: #fff;
      z-index: 99;

      .s_a_item {
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        padding: 1px 5px;
        line-height: 24px;
        cursor: pointer;
        font-size: 12px;
        -webkit-font-smoothing: antialiased;
        color: #666;

        div:first-child {
          width: 250px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        div:last-child {
          overflow: hidden;
          color: #aaa;
        }

        &:hover {
          background-color: rgb(255, 233, 188);
        }
      }
    }
  }
}

.sld_cart_wrap {
  float: right;
  position: relative;
  z-index: 99;
  height: 40px;
  width: 165px;
  // margin-top: 34px;
  margin-right: 13px;

  a,
  a:visited,
  a:hover {
    text-decoration: none;
    color: unset;
  }

  dl {
    margin-bottom: 0px;

    .cart_goods_num {
      font: 11px/16px Verdana;
      color: #fff;
      background: var(--color_price);
      text-align: center;
      display: inline-block;
      height: 16px;
      min-width: 16px;
      border: none 0;
      border-radius: 8px;
      margin-left: 10px;
    }
    .cart_icon_text_wrap{
      display: flex;
      align-items: center;
    }

    dt {
      position: absolute;
      z-index: 3;
      width: 165px;
      height: 38px;
      border: 1px solid #e3e3e3;
      background-color: #fff;
      cursor: pointer;
      font-weight: 400;

      .iconfont {
        margin: 0 13px 0 12px;
        color: var(--color_main);
        font-size: 26px;
        font-weight: 600;
        vertical-align: bottom;
        font-family: "iconfont" !important;
        font-size: 17px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        line-height: 36px;
      }
      

      &.cart_icon_text_wrap {
        a {
          color: #666666;
          font-size: 14px;
          line-height: 36px;
        }
      }
    }

    dd {
      .cart_goods {
        dl {
          padding-top: 8px;
        }

        dd {
          &.cart_goods_price {
            position: static;

            em {
              margin-right: 6px;
              width: auto;
              color: #666;

              &:nth-child(1) {
                display: block;
                font-weight: 600;
              }

              &:nth-child(2) {
                display: block;
                text-align: right;
                margin-top: 6px;
              }
            }
          }
        }
      }
    }
  }

  dd {
    position: absolute;
    top: 37px;
    right: 0;
    width: 355px;
    border: 1px solid #e3e3e3;
    background: #fff;
    z-index: 1;
  }

  &:hover .cart_more_view {
    display: inline-block;
  }
}

.cart_more_view {
  display: none;

  .empty_cart {
    width: 100%;
    position: relative;

    .empty_cart_line {
      position: absolute;
      width: 163px;
      right: 0;
      height: 2px;
      top: -2px;
      z-index: 999;
      background: #fff;
    }

    .empty_cart_txt {
      padding: 10px;
      color: #999;
    }
  }
}

.ld {
  position: relative;
  zoom: 1;
}

.cart_data {
  height: 300px;
  display: flex;
  flex-direction: column;
  position: relative;

  .cart_data_title {
    font-weight: 600;
    float: left;
    padding: 7px;
    line-height: 32px;
    height: 32px;
  }

  .cart_list {
    padding: 20px;
    overflow-y: scroll;

    .cart_list_pre {
      margin-bottom: 20px;

      .cart_pre_left {
        .cart_pre_img {
          width: 48px;
          height: 48px;
          border: 1px solid #e3e3e3;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .cart_pre_cen {
          width: 150px;
          margin-left: 20px;
          cursor: pointer;

          .cart_pre_name {
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }

          .cart_pre_spec {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-top: 5px;
          }
        }
      }

      .cart_pre_right {
        .cart_pre_price {
          color: #666;
        }

        .cart_pre_del {
          color: #666;
          cursor: pointer;
          margin-top: 10px;

          &:hover {
            color: var(--color_main);
          }
        }
      }
    }
  }

  .cart_bottom {
    width: 100%;
    height: 44px;
    border: 1px solid #e3e3e3;

    .cart_bottom_left {
      padding-left: 11px;
      height: 44px;

      span {
        color: #666;

        &:nth-child(2) {
          margin-left: 5px;
        }
      }
    }

    .cart_bottom_right {
      width: 110px;
      height: 44px;
      text-align: center;
      color: #fff;
      background-color: var(--color_main);
      line-height: 44px;
      cursor: pointer;
    }
  }
}

</style>